<template>
  <card>
    <div class="text-center">
      <base-button type="primary" @click.native="showSwal('custom-html')">{{
        $t("Work Under Maintanance !!!")
      }}</base-button>
    </div>
  </card>
</template>

<script>
import swal from "sweetalert2";

export default {
  methods: {
    showSwal(type) {
      type === "custom-html";
      {
        swal.fire({
          title: this.$t("We will come back Shortly"),
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
          },
          timer: 1500,
          html: ` <b>THANK YOU</b>`,
        });
      }
    },
  },
};
</script>
